//th
import story_1 from "./assets/images/storys/kapang-1.png";
import story_2 from "./assets/images/storys/kapang-2.png";

import chaMon1_1 from "./assets/images/chars/cha-mon1.png";
import chaDeva_1 from "./assets/images/chars/chaDeva-1.png";
import chaPang_1 from "./assets/images/chars/chaPang_1.png";


//en
import story_1_en from "./assets/images/storys/kapang-1_eng.png";
import story_2_en from "./assets/images/storys/kapang-2_eng.png";

import chaMon1_1_en from "./assets/images/chars/cha-mon1_en.png";
import chaDeva_1_en from "./assets/images/chars/chaDeva-1_en.png";
import chaPang_1_en from "./assets/images/chars/chaPang_1_en.png";


type Translations = {
  [key: string]: {

    //alert
    alert_1: string,
    alert_2: string,
    alert_3: string,
    alert_4: string,
    //cookie
    cookie: string,
    cookie_1: string,
    cookie_2: string,
    //support like
    supportLike_1: string,
    supportLike_2: string,
    //update
    update_1: string;
    update_2: string;
    update_3: string;
    update_4: string;
    update_5: string;
    update_6: string;
    update_7: string;
    update_8: string;
    update_9: string;
    update_10: string;

    //form
    formSendName: string;
    formFeedback: string;
    formMessage_1: string;
    formMessage_2: string;
    formMessage_3: string;
    formMessage_4: string;

    //privacy
    privacyTitle: string;
    effectiveDate: string;
    welcome: string;
    informationWeCollectTitle: string;
    informationWeCollect: string;
    contactInfo: string;
    usageInfo: string;
    additionalInfo: string;
    howWeUseInfoTitle: string;
    howWeUseInfo: string;
    respondToInquiries: string;
    improveExperience: string;
    notifyUpdates: string;
    analyzeUsage: string;
    shareInfoTitle: string;
    shareInfo: string;
    legalRequirements: string;
    protectRights: string;
    improveService: string;
    retainInfoTitle: string;
    retainInfo: string;
    yourRightsTitle: string;
    yourRights: string;
    policyChangesTitle: string;
    policyChanges: string;
    cookiePolicyTitle: string;
    cookieEffectiveDate: string;
    cookieUse: string;
    whatAreCookiesTitle: string;
    whatAreCookies: string;
    howWeUseCookiesTitle: string;
    howWeUseCookies: string;
    forAnalysis: string;
    improveExperienceWithCookies: string;
    manageCookiesTitle: string;
    manageCookies: string;
    questions: string;

    //story
    story_title: string;
    story_title_deva: string;
    story_1: string;
    story_2: string;

    //characters
    characters_deva: string;
    characters_pang: string;
    characters_mons: string;

    chaDeva_1: string;
    chaPang_1: string;
    chaMons_1: string;


  };
};

const translations: Translations = {
  th: {
    //alert
    alert_1: "คุณต้องยอมรับหรือปฏิเสธคุกกี้ก่อนที่จะส่งข้อความได้",
    alert_2: "คุณได้ส่งข้อความแล้ว กรุณารออีก 1 ชั่วโมงก่อนที่จะส่งข้อความใหม่",
    alert_3: "ขอบคุณที่ส่งข้อความหาเรา",
    alert_4: "ไม่สามารถส่งข้อความได้ กรุณาลองใหม่ภายหลัง",
    //cookie
    cookie: "เว็บไซต์นี้ใช้คุกกี้เพื่อเก็บข้อมูลที่จำเป็นต่อการพัฒนาเว็บไซต์ให้ดียิ่งขึ้น กรุณายอมรับคุกกี้เพื่อใช้งานต่อ",
    cookie_1: "ยอมรับ",
    cookie_2: "ไม่ยอมรับ",
    //support like
    supportLike_1: "ส่งกำลังใจ",
    supportLike_2: "ได้รับกำลังใจแล้ว",
    //update
    update_1: "February 26, 2025 / ประกาศอัปเดตรอบครั้งที่ 3",
    update_2: "อัปเดตสำคัญ",
    update_3: "ทางผู้พัฒนากำลังหาทุนต่อยอดเกม Deva of the forest เพื่อให้ตัวเกมผิดพลาดน้อยและออกมาสมบูรณ์ เนื่องจากตอนนี้ผู้พัฒนาสร้างเกมคนเดียวทำให้ขาดหลายสิ่งไม่ว่าจะเป็น เวลา, ทุนในการสร้าง, เครื่องมือ ตัวผู้สร้างทำงานบริษัทจึงมีเวลาทำเกมนี้แค่ช่วงวันหยุดซึ่งน้อยมาก.",
    update_10: "ต้นปีนี้จะมีการปล่อยวิดีโอ Demo ตัวเกมบางส่วนเพื่อโปรโมทและหาทุนต่อยอดเกม.",
    update_4: "ออกแบบ",
    update_5: "Levels Design: มีการเพิ่มแมพใหม่และปรับปรุงบางส่วน.",
    update_6: "Characters Design: ตัวละครหลักมีการปรับเปลี่ยนช่วง texture eye เพื่อให้ตัวละครมีดวงตาเป็นมิตร และชุดใหม่ดำเนินเรื่องในเกม.",
    update_7: "GamePlay Design: ตัวเกมพัฒนาในส่วนของการเล่นไปพอสมควร เนื้อเรื่องของเกมใช้วัฒนธรรมสากลผสมความเชื่อล้านนาหรืออีสานและเรื่องธรรมชาติ สีสันเอฟเฟก เสียงประกอบ เพิ่มอรรถรสให้ผู้เล่น.",
    update_8: "ระบบของเกม",
    update_9: "เกมมีระบบที่พัฒนาไปได้ 40% แล้ว สามารถเล่นได้ระดับหนึ่ง การต่อสู้หลบเรียงการหาวิธีเอาชนะศัตรูระดับต้นจนระดับสูง การไขปริศณา การปลดล็อก การดำเนินเรื่องและอีกหลายระบบ.",
        //form
    formSendName: "ชื่อผู้ส่ง:",
    formFeedback: "ข้อความที่จะส่ง (แนะนำ หรือ ติดต่อ):",
    formMessage_1: "ส่งข้อความถึงเรา",
    formMessage_2: "คุณสามารถส่งข้อความถึงเราได้ เราจะอ่านทุกความคิดเห็น.",
    formMessage_3: "สามารถแนะนำหรือเสนอแนะแนวทางการพัฒนาได้.",
    formMessage_4: "โปรดใส่อีเมลของคุณเพื่อให้เราสามารถติดต่อกลับได้ เราจะพิจารณาการตอบกลับทุกข้อความ.",
    //privacy
    privacyTitle: 'นโยบายความเป็นส่วนตัว',
    effectiveDate: 'วันที่มีผลบังคับใช้: 2023-2024',
    welcome: 'ยินดีต้อนรับสู่เว็บไซต์ <a href="http://www.wigiway.com">www.wigiway.com</a> ซึ่งดำเนินการโดย Wigiway Studio (“เรา” หรือ “ของเรา”) นโยบายความเป็นส่วนตัวนี้อธิบายถึงวิธีการที่เรารวบรวม ใช้ และปกป้องข้อมูลส่วนบุคคลของคุณที่เกี่ยวข้องกับการใช้งานเว็บไซต์นี้',
    informationWeCollectTitle: '1. ข้อมูลที่เรารวบรวม',
    informationWeCollect: 'เราอาจรวบรวมข้อมูลประเภทต่อไปนี้เมื่อคุณเยี่ยมชมเว็บไซต์ของเรา:',
    contactInfo: 'ข้อมูลการติดต่อ: เช่น ชื่อ และที่อยู่อีเมลของคุณเมื่อคุณกรอกแบบฟอร์มติดต่อ',
    usageInfo: 'ข้อมูลการใช้งานเว็บไซต์: รวมถึงข้อมูลการเยี่ยมชมเว็บไซต์ของคุณ เช่น หน้าต่างๆ ที่คุณเยี่ยมชม เวลาที่คุณใช้ในแต่ละหน้า และข้อมูลการคลิก',
    additionalInfo: 'ข้อมูลที่คุณให้เพิ่มเติม: เช่น ข้อความหรือคำถามที่คุณส่งผ่านฟอร์มติดต่อ',
    howWeUseInfoTitle: '2. การใช้ข้อมูลของคุณ',
    howWeUseInfo: 'ข้อมูลที่เรารวบรวมอาจถูกใช้เพื่อวัตถุประสงค์ดังต่อไปนี้:',
    respondToInquiries: 'เพื่อตอบคำถามหรือข้อสงสัยของคุณ',
    improveExperience: 'เพื่อปรับปรุงประสบการณ์การใช้งานเว็บไซต์ของคุณ',
    notifyUpdates: 'เพื่อแจ้งให้คุณทราบเกี่ยวกับการอัพเดทและกิจกรรมต่างๆ ที่เกี่ยวข้องกับการพัฒนาเกม',
    analyzeUsage: 'เพื่อวิเคราะห์การใช้งานเว็บไซต์และทำให้เราเข้าใจผู้เยี่ยมชมของเรามากขึ้น',
    shareInfoTitle: '3. การแบ่งปันข้อมูลของคุณ',
    shareInfo: 'เราไม่แบ่งปันข้อมูลส่วนบุคคลของคุณกับบุคคลภายนอก ยกเว้นในกรณีต่อไปนี้:',
    legalRequirements: 'เมื่อมีข้อกำหนดทางกฎหมาย',
    protectRights: 'เพื่อปกป้องสิทธิ์และความปลอดภัยของเรา',
    improveService: 'เพื่อปรับปรุงการให้บริการของเราโดยใช้เครื่องมือวิเคราะห์จากบุคคลที่สาม',
    retainInfoTitle: '4. การเก็บรักษาข้อมูลของคุณ',
    retainInfo: 'เราจะเก็บรักษาข้อมูลของคุณตราบเท่าที่จำเป็นเพื่อให้บริการและปฏิบัติตามวัตถุประสงค์ที่อธิบายไว้ในนโยบายนี้ เราจะใช้มาตรการรักษาความปลอดภัยที่เหมาะสมเพื่อปกป้องข้อมูลส่วนบุคคลของคุณ',
    yourRightsTitle: '5. สิทธิ์ของคุณ',
    yourRights: 'คุณมีสิทธิ์ในการเข้าถึง แก้ไข หรือลบข้อมูลส่วนบุคคลของคุณที่เรารวบรวม หากคุณต้องการใช้สิทธิ์เหล่านี้ โปรดติดต่อเราผ่านทางข้อมูลการติดต่อที่ระบุไว้ในเว็บไซต์นี้',
    policyChangesTitle: '6. การเปลี่ยนแปลงนโยบายนี้',
    policyChanges: 'เราอาจปรับปรุงนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราว การเปลี่ยนแปลงใดๆ จะถูกโพสต์บนหน้านี้ และเราจะแจ้งให้คุณทราบหากมีการเปลี่ยนแปลงที่สำคัญ',
    cookiePolicyTitle: 'นโยบายการใช้คุกกี้',
    cookieEffectiveDate: 'วันที่มีผลบังคับใช้: 2023-2024',
    cookieUse: 'เว็บไซต์ของเราใช้คุกกี้เพื่อพัฒนาประสบการณ์การใช้งานของคุณและเก็บข้อมูลที่จำเป็นสำหรับการพัฒนาเว็บไซต์ให้ดียิ่งขึ้น',
    whatAreCookiesTitle: '1. คุกกี้คืออะไร?',
    whatAreCookies: 'คุกกี้คือไฟล์ข้อมูลขนาดเล็กที่ถูกส่งไปยังเบราว์เซอร์ของคุณจากเว็บไซต์ที่คุณเยี่ยมชมและถูกจัดเก็บไว้ในอุปกรณ์ของคุณ คุกกี้ช่วยให้เว็บไซต์จดจำข้อมูลเกี่ยวกับการเยี่ยมชมของคุณ ทำให้การใช้งานเว็บไซต์ในครั้งต่อไปสะดวกยิ่งขึ้น',
    howWeUseCookiesTitle: '2. เราใช้คุกกี้อย่างไร?',
    howWeUseCookies: 'เรามีการใช้คุกกี้เพื่อวัตถุประสงค์ดังต่อไปนี้:',
    forAnalysis: 'เพื่อการวิเคราะห์: คุกกี้ช่วยให้เรารวบรวมข้อมูลเกี่ยวกับการใช้งานเว็บไซต์ของคุณ ซึ่งจะช่วยให้เราปรับปรุงเว็บไซต์และบริการของเราได้ดียิ่งขึ้น',
    improveExperienceWithCookies: 'เพื่อปรับปรุงประสบการณ์การใช้งาน: คุกกี้ช่วยให้เราจดจำการตั้งค่าและการใช้งานเว็บไซต์ของคุณ เพื่อทำให้การเยี่ยมชมเว็บไซต์ในครั้งต่อไปเป็นไปอย่างราบรื่น',
    manageCookiesTitle: '3. การจัดการคุกกี้',
    manageCookies: 'คุณสามารถตั้งค่าเบราว์เซอร์ของคุณให้ยอมรับหรือปฏิเสธคุกกี้ได้ หากคุณเลือกที่จะปฏิเสธคุกกี้ คุณยังสามารถใช้งานเว็บไซต์ของเราได้ แต่บางฟังก์ชั่นอาจไม่ทำงานอย่างถูกต้อง',
    questions: 'หากคุณมีคำถามหรือข้อสงสัยเกี่ยวกับนโยบายความเป็นส่วนตัวหรือการใช้คุกกี้ของเรา กรุณาติดต่อเราได้ที่ <a href="http://www.wigiway.com">www.wigiway.com</a> ส่งข้อความทาง Contact',
    //story
    story_title : "เริ่มต้นเรื่องราว",
    story_title_deva: "เทพผู้พิทักษ์",
    story_1 : story_1,
    story_2 : story_2,

    //characters
    characters_deva : "เทวาดา",
    characters_mons : "ปีศาจ",
    characters_pang : "นะโม",

    chaDeva_1 : chaDeva_1,
    chaMons_1 : chaMon1_1,
    chaPang_1 : chaPang_1
  },
  en: {
    //alert
    alert_1: "You must accept or decline cookies before sending a message.",
    alert_2: "You have sent a message. Please wait another hour before sending a new one.",
    alert_3: "Thank you for messaging us.",
    alert_4: "Unable to send message. Please try again later.",
        //cookie
    cookie: "This website uses cookies to collect necessary information to improve its development. Please accept cookies to continue using the site.",
    cookie_1: "Accept",
    cookie_2: "Decline",
    //support like
    supportLike_1: "Sending Support",
    supportLike_2: "We got Support",
    //update
  update_1: "February 26, 2025 / Second Update Announcement",  
  update_2: "Important Update",  
  update_3: "The developer is currently seeking funding to further enhance Game Deva of the Forest, aiming to minimize errors and deliver a polished final product. As the game is being developed by a solo creator, there are limitations in terms of time, funding, and tools. The developer works a full-time job and can only work on the game during weekends and holidays, which leaves very limited time for development.",  
  update_4: "Design",  
  update_5: "Level Design: New maps have been added, and some existing areas have been improved.",  
  update_6: "Character Design: The main character’s eye texture has been adjusted to give a friendlier appearance. Additionally, a new outfit has been introduced to match the in-game storyline.",  
  update_7: "Gameplay Design: The gameplay has progressed significantly. The story combines global cultural elements with traditional Lanna or Isan beliefs and themes of nature. Enhanced visual effects, vibrant colors, and immersive soundtracks enrich the player's experience.",  
  update_8: "Game System",  
  update_9: "The game system is about 40% complete. Players can now engage in combat, evade enemies, and discover various strategies to defeat both low-level and high-level foes. The game also includes puzzle-solving, unlocking mechanics, story progression, and many other features.",
  update_10: "At the beginning of this year, a demo video showcasing parts of the game will be released to promote the project and secure additional funding for its continuation.",
  //form
    formSendName: "Your name",
    formFeedback: "Feedback or contact information",
    formMessage_1: "Contact Us",
    formMessage_2: "You can send us a message, and we will read every comment.",
    formMessage_3: "You can suggest or provide feedback on the development direction.",
    formMessage_4: "Please include your email address so we can get back to you. We will consider responding to every message.",
        //privacy
    privacyTitle: 'Privacy Policy',
    effectiveDate: 'Effective Date: 2023-2024',
    welcome: 'Welcome to the website <a href="http://www.wigiway.com">www.wigiway.com</a>, operated by Wigiway Studio (“we” or “us”). This Privacy Policy describes how we collect, use, and protect your personal information in relation to your use of this website.',
    informationWeCollectTitle: '1. Information We Collect',
    informationWeCollect: 'We may collect the following types of information when you visit our website:',
    contactInfo: 'Contact Information: such as your name and email address when you fill out a contact form',
    usageInfo: 'Website Usage Information: including your visit details such as the pages you visit, the time you spend on each page, and click information',
    additionalInfo: 'Additional Information You Provide: such as messages or questions you send through the contact form',
    howWeUseInfoTitle: '2. How We Use Your Information',
    howWeUseInfo: 'The information we collect may be used for the following purposes:',
    respondToInquiries: 'To respond to your questions or inquiries',
    improveExperience: 'To improve your experience on our website',
    notifyUpdates: 'To inform you about updates and activities related to game development',
    analyzeUsage: 'To analyze website usage and help us understand our visitors better',
    shareInfoTitle: '3. Sharing Your Information',
    shareInfo: 'We do not share your personal information with third parties except in the following cases:',
    legalRequirements: 'When required by law',
    protectRights: 'To protect our rights and safety',
    improveService: 'To improve our services using third-party analytical tools',
    retainInfoTitle: '4. Retaining Your Information',
    retainInfo: 'We will retain your information for as long as necessary to provide services and fulfill the purposes described in this policy. We will use appropriate security measures to protect your personal information.',
    yourRightsTitle: '5. Your Rights',
    yourRights: 'You have the right to access, correct, or delete your personal information that we collect. If you wish to exercise these rights, please contact us using the contact information provided on this website.',
    policyChangesTitle: '6. Changes to This Policy',
    policyChanges: 'We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you if there are significant changes.',
    cookiePolicyTitle: 'Cookie Policy',
    cookieEffectiveDate: 'Effective Date: 2023-2024',
    cookieUse: 'Our website uses cookies to improve your browsing experience and collect information necessary for website development.',
    whatAreCookiesTitle: '1. What Are Cookies?',
    whatAreCookies: 'Cookies are small data files sent to your browser from the website you visit and stored on your device. Cookies help the website remember information about your visit, making your next visit smoother.',
    howWeUseCookiesTitle: '2. How We Use Cookies',
    howWeUseCookies: 'We use cookies for the following purposes:',
    forAnalysis: 'For Analysis: Cookies help us collect information about your website usage, which helps us improve our website and services',
    improveExperienceWithCookies: 'To Improve User Experience: Cookies help us remember your settings and preferences to make your next visit smoother',
    manageCookiesTitle: '3. Managing Cookies',
    manageCookies: 'You can set your browser to accept or reject cookies. If you choose to reject cookies, you can still use our website, but some features may not function properly.',
    questions: 'If you have any questions or concerns about our Privacy Policy or use of cookies, please contact us at <a href="http://www.wigiway.com">www.wigiway.com</a> via the contact form',

    //story
    story_title : "Story Deva of the forest",
    story_title_deva: "The Lost Guardians of Deva",
    story_1 : story_1_en,
    story_2 : story_2_en,

    //characters
    characters_deva : "Te-va-da",
    characters_mons : "Monsters",
    characters_pang : "Na-no",

    chaDeva_1 : chaDeva_1_en,
    chaMons_1 : chaMon1_1_en,
    chaPang_1 : chaPang_1_en

  }
};

export default translations;
